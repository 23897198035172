.flex-center {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.copy-icon {
  margin-left: 10px;
  width: 15px;
  height: 15px;
  fill: #000;
}
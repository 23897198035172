.myramyth-logo {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-content: center;
}

  .myramyth-logo h1 {
    height: 100%;
    align-content: center;
  }

@media screen and (max-width: 430px) {
  .myramyth-logo img {
    display: block;
    max-width: 80%;
    width: auto;
    height: auto;
  }

  .myramyth-burger {
    position: absolute; 
    right: 0;
    margin: 28px;
  }
}

.myramyth-burger {
  position: absolute; 
  right: 0;
  margin: 28px;
}

.story {
  white-space: pre-wrap;
}

  .story p {
    margin-bottom: 5px;
  }

  .story button {
    margin: 10px 0px;
  }

.slider-bar {
  width: 30px;
  height: 100%;
  background-color: black;
  align-content: center;
  justify-content: center;
  text-align: center;
  font-size: 2em;
  border: 1px solid white;
  border-radius: 20px;
}


* {
    box-sizing: border-box;
}

.content {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-weight: 500;
    background: linear-gradient(rgba(0,0,0,0.5) 50%,  rgba(48, 95, 139, 0.5));
    color: white;
    font-size: 1.5rem;
    z-index: 99;
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    /* For tablet phones: */
    .content {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 576px) {
    /* For mobile phones: */
    .content {
        font-size: 1rem;
    }
}

.roller-total {
    margin-top: 30px;
}

.dice-handler {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.utopia-nav {
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100vw;
    display: flex; /* Use flex instead of inline-flex */
    flex-direction: row;
    align-items: center; /* Center items horizontally */
    justify-content: center;
    align-content: center;
    margin-top: 25px;
    background: rgba(0,0,0,0.1);
    color: white;
}

    .utoipia-nav div {
        position: relative;
        top: 20px; /* Adjust the top position if needed */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .utopia-nav ul {
        list-style: none; /* Ensure there are no bullet points */
        padding: 0;
        margin: 10px 0; /* Adjust space between elements */
        display: inline-flex;
        font-size: 30px;
        list-style-type: none;
        gap: 50px;
        text-transform: uppercase;
        font-family: Cambria;
    }

.utopia-main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    align-content: center;
    justify-content: center;
}

.dice-roller {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 40px;
    /*background-color: rgba(0,0,0,1);*/
}

.dice-container {
    display: flex;
    flex-direction: row;
    position: relative;
    left: 0;
    top: 0;
    gap: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 40%;
    width: 100%;
}

.roller-container {
    justify-content: center;
    align-content: center;
    gap: 10px;
}

.roller-buttons {
    color: white;
    font-size: 1.2em;
    align-content: center;
    justify-content: center;
    flex-direction: row;
}

.character-stats {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    text-align: center;    
}

    .character-stats input {
        height: 100%;
        margin: 10px;
        width: 100px;
        text-align: center;
        padding: 5px;
        font-size: 1em;
        border-radius: 25px;
    }

    .character-stats .row {
    }

.roller-button {
    background-color: rgba(255,255,255,1);
    color: black;
    border-radius: 25px;
    padding: 10px;
    position: relative;
    margin: auto 10px;
}

    /* .roller-button::after {
        content: '';
        background-image: conic-gradient(red, blue, green, red);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        translate: -50% -50%;
        transform: scale(1.1);
        padding: 3px;
        border-radius: 25px;
    }
    
    .roller-button::before {
        filter: blur(2px);
    } */

.add-dice {
    width: 100px;
    height: 100px;
    top: 0;
    left: 0;
    border: 1px solid white;
    border-radius: 25px;
    background-color: rgba(255,255,255,0.2);
}

@keyframes rotate {
	to {
		--angle: 360deg;
	}
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.white-black-border {
    --angle: 0deg;
    border: 1vmin solid;
    animation: 10s rotate linear infinite;
    border-radius: 30px;
    border-image: conic-gradient(from var(--angle), white, black, white, black, white) 1;
}

.rainbow-border {
    --angle: 0deg;
    border: 1vmin solid transparent;
    border-radius: 10px;
    animation: 10s rotate linear infinite;
    background: 
        linear-gradient(white, white) padding-box,
        conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) border-box;
    background-clip: padding-box, border-box;
}


.dice {
    width: 200px;
    height: 200px;
    top: 0;
    left: 0;
    border: 1px solid white;
    align-content: center;
    justify-content: center;
    text-align: center;
    border-radius: 25px;
    font-size: 2em;
    background-color: rgba(255,255,255,0.2);
}

    .dice > p {
        margin-top: 20px;
        margin-bottom: 20px;
    }

@media only screen and (max-width: 768px) {
    .add-dice {
        width: 80px;
        height: 80px;
    }
    
    .dice {
        width: 100px;
        height: 100px;
        font-size: 1.5em;
    }

        .dice > p {
            margin-top: 5px;
            margin-bottom: 5px;
        }
}

.compendium-grid {
    font-family: Arial, Helvetica, sans-serif;
    min-height: 100vh;
    left: 0;
    top: 0;
    padding-top: 100px;
    position: absolute;
    width: 100vw;
    align-content: start;
    justify-content: center;
    padding-left: 20px;
    /*background-color: rgba(255,255,255,0.1);*/
}

    .compendium-grid h2 {
        margin-bottom: 20px;
    }

    .compendium-grid li {
        margin-top: 5px;
    }


.item-tree {
    width: 100%;
    align-content: left;
    justify-content: left;
    text-align: left;
}

.item-list {
    width: 100%;
    text-align: left;
    display: flex;
    justify-items: start;
    align-items: start;
    flex-direction: column;
}

.list-item {
    display: flex;
    padding: 10px;
    width: 100%;
    text-align: left;
}

.sub-list-item {
    display: flex;
    
    font-size: 0.8em;
}

.nav-drawer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 10px;
}

.nav-burger {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1000;
}

.compendium-button {
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 1000;
}

.download-grid {
    margin-top: 50px;
}

@media only screen and (max-width: 1200px) {
    .download-grid {
        margin-top: -100px;
    }
}

.description {
    border-radius: 5px;
    align-content: start;
    justify-content: start;
    color: white;
    text-align: left;
    font-weight: 500;
    padding: 20px;
    border-radius: 20px;
    max-height: 90vh;
}

.mobile-description {
    text-align: left;
    top: 0;
    margin: 20px;
}

.blur-edges {
    max-height: 90vh;
    padding-bottom: 60px;
    padding-top: 40px;
    margin-top: 10px;
    margin-bottom: 20px;
    overflow: auto;
    -webkit-mask-image: linear-gradient(transparent, black 5%, black 90%, transparent);
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

[class*="col-"] {
    float: left;
    padding: 15px;
    flex-direction: column;
    display: flex;
    align-items: center;
  }

.row::after {
    content: "";
    clear: both;
    display: table;
}

.row {
    align-items: start;
    justify-items: start;
}

button {
    background: none;
    border: none;
    text-align: center;
    font-size: 2rem;
    color: white;
    font-variant: small-caps;
}


.logo {
    width: 350px;
}

h1 {
    font-variant: small-caps;
    font-size: 5rem;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
      width: 100%;
    }
  }

.license-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1em;
    color: white;
    width: 80%;
    margin-top: 50px;
    overflow-y: scroll;
}

.license {
    display: grid;
    grid-template-columns: repeat(2 1fr);
    text-align: left;    
    font-size: 0.7em;

    & .column-1 {
        grid-column: 1;
    }

    & .column-2 {
        grid-column: 2;
    }

    & > ol {
        list-style-type: none;
        padding: 0;
        margin: 1em;
        width: 100%;
    }

    & > ol li {
        padding: 0.5em;
        width: 95%;
        list-style-type: none;
    }
}
#gradient
{
  width: 100%;
  height: 1080px;
  padding: 0px;
  margin: 0px;
  z-index:2;
}

@-webkit-keyframes twinkle_one {
  0%   { opacity: 1; }
  30%  { opacity: 1; }
  70%  { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes twinkle_one {
  0%   { opacity: 1; }
  30%  { opacity: 1; }
  70%  { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes twinkle_one {
  0%   { opacity: 1; }
  30%  { opacity: 1; }
  70%  { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes twinkle_one {
  0%   { opacity: 1; }
  30%  { opacity: 1; }
  70%  { opacity: 0; }
  100% { opacity: 1; }
}


@-webkit-keyframes twinkle_two {
  0%   { opacity: 1; }
  25%  { opacity: 0; }
  80%  { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes twinkle_two {
  0%   { opacity: 1; }
  25%  { opacity: 0; }
  80%  { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes twinkle_two {
  0%   { opacity: 1; }
  25%  { opacity: 0; }
  80%  { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes twinkle_two {
  0%   { opacity: 1; }
  25%  { opacity: 0; }
  80%  { opacity: 0; }
  100% { opacity: 1; }
}


@-webkit-keyframes twinkle_three {
  0%   { opacity: 1; }
  30%  { opacity: 0; }
  40%  { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes twinkle_three {
  0%   { opacity: 1; }
  30%  { opacity: 0; }
  40%  { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes twinkle_three {
  0%   { opacity: 1; }
  30%  { opacity: 0; }
  40%  { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes twinkle_three {
  0%   { opacity: 1; }
  30%  { opacity: 0; }
  40%  { opacity: 0; }
  100% { opacity: 1; }
}

.galaxy{
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  background-color: rgba(0,0,0,.1);
  max-width: 100vw;
  overflow: hidden;
}
      
      .star{
        position: absolute;
        background-color: white;
      }

      .star-type1{
        width: 1px;
        height: 1px;
        border-radius: 2px;
        -webkit-box-shadow: 0 0 1px 1px white;
                box-shadow: 0 0 1px 1px white;
        -webkit-animation: twinkle_one 3s ease-in-out infinite;
           -moz-animation: twinkle_one 3s ease-in-out infinite;
             -o-animation: twinkle_one 3s ease-in-out infinite;
                animation: twinkle_one 3s ease-in-out infinite;
      }
      .star-type2{
        width: 2px;
        height: 2px;
        border-radius: 2px;
        -webkit-box-shadow: 0 0 2px 1px white;
                box-shadow: 0 0 2px 1px white;
        -webkit-animation: twinkle_two 12s ease-in-out infinite;
           -moz-animation: twinkle_two 12s ease-in-out infinite;
             -o-animation: twinkle_two 12s ease-in-out infinite;
                animation: twinkle_two 12s ease-in-out infinite;
      }
      .star-type3{
        width: 2px;
        height: 2px;
        border-radius: 2px;
        -webkit-box-shadow: 0 0 2px 0 white;
                box-shadow: 0 0 2px 0 white;
        -webkit-animation: twinkle_three 7s ease-in-out infinite;
           -moz-animation: twinkle_three 7s ease-in-out infinite;
             -o-animation: twinkle_three 7s ease-in-out infinite;
                animation: twinkle_three 7s ease-in-out infinite;
      }
      
    
	/*mask*/
/* 
  Based from this article from Divya Manian - 
  http://nimbupani.com/using-background-clip-for-text-with-css-fallback.html
*/
@import url(https://fonts.googleapis.com/css?family=Oswald:400,700);
* {
  margin: 0;
  padding: 0;
}

*,
:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  font-family: 'Oswald', sans-serif;
  color: #fff;
  background-color: #000;
}

.wrapper {
  text-align: center;
}

.title {
  font-size: 2em;
  position: relative;
  margin: 0 auto 1em;
  padding: 1em 1em .25em 1em;
  text-align: center;
  text-transform: uppercase;
}

.title:after {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 240px;
  height: 4px;
  margin-left: -120px;
  content: '';
  background-color: #fff;
}

/* Clip text element */
.clip-text {
  font-size: 6em;
  font-weight: bold;
  line-height: 1;
  position: relative;
  display: inline-block;
  margin: .25em;
  padding: .5em .75em;
  text-align: center;
  /* Color fallback */
  color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.clip-text:before,
.clip-text:after {
  position: absolute;
  content: '';
}

/* Background */
.clip-text:before {
  z-index: -2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: inherit;
}

/* Text Background (black zone) */
.clip-text:after {
  position: absolute;
  z-index: -1;
  top: .125em;
  right: .125em;
  bottom: .125em;
  left: .125em;
  background-color: #000;
}

/* Change the background position to display letter when the black zone isn't here */
.clip-text--no-textzone:before {
  background-position: -.65em 0;
}

.clip-text--no-textzone:after {
  content: none;
}

/* Use Background-size cover for photo background and no-repeat background */
.clip-text--cover,
.clip-text--cover:before {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}

/* Background image from http://thepatternlibrary.com/ and http://lorempixel.com */
.clip-text_one {
  background-image: url(http://lorempixel.com/480/200/abstract/7);
}

.clip-text_two {
  background-image: url(http://lorempixel.com/480/200/abstract);
}

.clip-text_tree {
  background-image: url(http://lorempixel.com/480/200/abstract/2);
}

.clip-text_four {
  background-image: url(http://lorempixel.com/480/200/abstract/4);
}

.clip-text_five {
  background-image: url(http://lorempixel.com/480/200/abstract/5);
}

.clip-text_six {
  background-image: url(http://lorempixel.com/480/200/abstract/9);
}

.clip-text_seven {
  background-image: url(http://lorempixel.com/480/200/abstract/8);
}

.clip-text_eight {
  background-image: url(http://lorempixel.com/410/200/people);
}

.clip-text_nine {
  background-image: url(http://lorempixel.com/480/200/nightlife);
}

.clip-text_ten {
  background-image: url(http://lorempixel.com/480/200/nightlife/8);
}

.clip-text_eleven {
  background-image: url(http://lorempixel.com/480/200/fashion/10/cc);
  background-size: cover;
}

.clip-text_twelve {
  background-image: url(https://static.pexels.com/photos/32237/pexels-photo.jpg);
}

.clip-text_thirteen {
  background-image: url(http://lorempixel.com/480/200/food/5/cc);
}

.clip-text_fourteen {
  background-image: url(http://lorempixel.com/480/200/city/9/cc);
}

.clip-text_fifteen {
  background-image: url(http://lorempixel.com/480/200/nightlife/5);
}

/*stars variation 2*/


@keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-moz-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}
@-ms-keyframes move-twink-back {
    from {background-position:0 0;}
    to {background-position:-10000px 5000px;}
}

@keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-webkit-keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-moz-keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
}
@-ms-keyframes move-clouds-back {
    from {background-position: 0;}
    to {background-position:10000px 0;}
}

.stars, .twinkling, .clouds {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  display:block;
  z-index: 1;
}

.stars {
  background:#000 url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
  z-index: 1;
}
@keyframes flash {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes greater-right {
  0% {
    transform: translateX(-90%);
    opacity: 0;
  }
  20% {
    transform: translateX(-90%);
    opacity: 1;
  }
  80% {
    transform: translateX(-20%);
    opacity: 1;
  }
  100% {
    transform: translateX(-20%);
    opacity: 0;
  }
}

@keyframes less-left {
  0% {
    transform: translateX(90%);
    opacity: 0;
  }
  20% {
    transform: translateX(90%);
    opacity: 1;
  }
  80% {
    transform: translateX(20%);
    opacity: 1;
  }
  100% {
    transform: translateX(20%);
    opacity: 0;
  }
}


body {
  display: block;
  margin: 0px;
  font-size: 100%;
}

.container {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scrollbar-color: white black;
  scrollbar-width: none;
}

a {
  text-decoration: none;
  color: inherit;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #1f2937;
}

.row {
  align-items: center;
  justify-content: center;
  height: 100%;
}

  .row img {
    width: 250px;
    position: relative;
  }

  .row video {
    max-width: 80%;
  }

.image-gradient-container {
  text-align: center;
  width: 100%;
  justify-content: center;
  align-content: center;
  justify-self: center;
  align-self: center;
}

  .image-gradient-container:first-child {
    grid-column: 1;
  }

  .image-gradient-container:last-child {
    grid-column: 2;
  }

.slides {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  color: #fff;
  scroll-snap-align: start;
}

@media only screen and (max-width: 576px) {
  /* For mobile phones: */
  .slides div {
      min-width: max( 20rem, 90% );
      padding: 1rem 0.5rem;
  }
}

.slide-1 {
  background-color: white;
  min-height: 100vh;
}

.slide-2 {
  background-color: goldenrod;
  min-height: 100vh;
  justify-content: center;
  align-content: center;
}

.slides-button-container {
  position: relative;
}

.slides-button {
  position: absolute;
  left: 20px;
  top: 20px;
  display: inline-flex;
  height: 70px;
  width: 310px;
  justify-content: center;
  font-size: 1.5em;
  background-color: black;
  padding: 0.5em 1.5em;
  border: 2px solid white;
  border-radius: 15px;
}

  .slides-button:hover {
    cursor: pointer;
  }

  .slides-button .gt {
    animation: greater-right 1s infinite;
  }  

  .slides-button .lt {
    animation: less-left 1s infinite;
  }  

.slides-button-background {
  position: relative;
  left: 0;
  top: 0;
  width: 350px;
  height: 110px;
  padding: 20px;
  background-color: rgba(7, 107, 146, 0.692);
  animation: flash 5s infinite;
  border-radius: 15px;
}

.slides h1 {
  text-align: center;
  font-size: 5em;
  font-family: Cambria;
  font-weight: 500;
  font-variant: small-caps;
}

.slides video {
  width: 100px;
}

.slides > .row {
  width: 100vw;
  height: 100vh;
}

.slides-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 50px;
  container-name: header;
}

.slides-header h1 {
  color: black;
  height: auto;
  text-align: center;
}

.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.row {
  display: flex;
  flex-direction: row;
}

.main-page {
  display: flex;
  flex-direction: column;
}

.socials {
  color: black;
  display: inline-flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 30px;
}

.socials img {
  max-width: 30px;
  margin: 5px;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
    row-gap: 100px;
  }
  
    .row video {
      display: none;
    }

  .slides img {
    width: 50%;
  }

  .slides h1 {
    font-size: 2em;
  }
}
